import {
  Context,
  createContext,
  FC,
  ReactNode,
  useContext,
  useState,
} from 'react';

import UserScopes from '../config/userScopes';

import SignInContextDefaults from '../types/SignInContextDefaults';

const SignInContext = createContext<SignInContextDefaults | {}>(
  {},
) as Context<SignInContextDefaults>;

const SignInProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const hasAuthToken = () => localStorage.hasOwnProperty('auth_token');
  const [isSignedIn, setIsSignedIn] = useState(hasAuthToken);
  const scopes: UserScopes[] = localStorage.hasOwnProperty('scopes')
    ? JSON.parse(localStorage.getItem('scopes') || '')
    : [];

  return (
    <SignInContext.Provider value={{ isSignedIn, setIsSignedIn, scopes }}>
      {children}
    </SignInContext.Provider>
  );
};

const useSignInContext = () => useContext(SignInContext);

export { SignInProvider, useSignInContext };
