import { Flex, Spinner, SpinnerProps, Text } from '@chakra-ui/react';

import styleProps from './styles';

const PageLoading = ({ message = 'Hang on!', ...props }) => (
  <Flex {...styleProps.wrapper} {...props}>
    <Spinner {...(styleProps.spinner as SpinnerProps)} />
    <Text {...styleProps.text}>{message}</Text>
  </Flex>
);

export default PageLoading;
