import { extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

import { Dict } from '@chakra-ui/utils';

const colors = {
  text: {
    dark: '#ffffff',
    light: '#333035',
  },
  background: {
    dark: '#222526',
    light: '#ffffff',
  },
  bodyBackground: {
    dark: '#18191A',
    light: '#f5f5f5',
  },
  message: {
    dark: '#61d6cc',
    light: '#bef7f2',
  },
  field: {
    dark: '#151515',
    light: '#ffffff',
    disabled: {
      dark: '#1b1b1b',
      light: '#f2f2f2',
    },
  },
  badge: {
    bg: {
      blue: {
        dark: 'rgba(144, 205, 244, 0.16)',
        light: '#bee3f8',
      },
      green: {
        dark: 'rgba(154, 230, 180, 0.16)',
        light: '#c6f6d5',
      },
      grey: {
        dark: 'rgba(226, 232, 240, 0.16)',
        light: '#edf2f7',
      },
      purple: {
        dark: 'rgba(214, 188, 250, 0.16)',
        light: '#e9d8fd',
      },
      red: {
        dark: 'rgba(254, 178, 178, 0.16)',
        light: '#fed7d7',
      },
    },
    text: {
      blue: {
        dark: '#90cdf4',
        light: '#2a4365',
      },
      green: {
        dark: '#9ae6b4',
        light: '#22543d',
      },
      grey: {
        dark: '#e2e8f0',
        light: '#1a202c',
      },
      purple: {
        dark: '#d6bcfa',
        light: '#44337a',
      },
      red: {
        dark: '#feb2b2',
        light: '#822727',
      },
    },
  },
};

const components = {
  Alert: {
    baseStyle: {
      container: {
        mx: '30px',
        my: '20px',
      },
    },
  },
};

const styles = {
  global: (props: Dict<any>) => ({
    body: {
      bg: mode('#f5f5f5', '#18191A')(props),
      color: mode('text.light', 'text.dark')(props),
      fontFamily: 'source-sans',
    },
    p: {
      color: mode('text.light', 'text.dark')(props),
    },
    '.color-purchase': {
      fill: '#e3753d', // or background-color: red; depending on your styling needs
    },
    '.color-freesub': {
      fill: '#5bdedc', // or background-color: red; depending on your styling needs
    },
    '.color-purchase-and-free': {
      fill: '#c047c4',
    },
  }),
};

const typography = {
  letterSpacings: {
    slightlyWide: '0.0125rem',
  },
};

export default extendTheme({ colors, components, styles, typography });
