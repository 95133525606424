import { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import Props from './types';

const RedirectRoute: FC<Props> = ({
  redirectCondition,
  redirectPath = '/login',
}) => {
  return !redirectCondition ? (
    <Outlet />
  ) : (
    <Navigate to={redirectPath} replace />
  );
};

export default RedirectRoute;
