import { StyleGenerator } from '../../types/Styles';

const styleGenerator: StyleGenerator = (isDark) => ({
  logo: {
    h: 4,
    w: 'auto',
  },
  modal: {
    backgroundColor: `background.${isDark ? 'dark' : 'light'}`,
    borderColor: 'black',
    borderRadius: 12,
    boxShadow: 'dark-lg',
    direction: 'column',
    h: 'auto',
    justifyContent: 'center',
    maxW: 'md',
    mt: 6,
    px: [7, 7, 8, 10],
    py: [8, 10, 10, 12],
    w: 'full',
  },
  modalWrapper: {
    alignItems: 'center',
    bgGradient: `linear-gradient(#0000007f, #0000007f), url('/img/background.jpg')`,
    bgPosition: 'center',
    bgRepeat: 'no-repeat',
    bgSize: 'cover',
    h: '100vh',
    justifyContent: 'center',
    px: [4, 5, 6, 8],
    w: '100vw',
  },
  signInButton: {
    alignItems: 'center',
    bgColor: `background.${isDark ? 'dark' : 'light'}`,
    borderColor: isDark ? '#2f2f2f' : '#eaeaea',
    borderRadius: 44,
    borderWidth: '2px',
    boxShadow: 'xs',
    cursor: 'pointer',
    fontFamily: 'source-sans',
    justifyContent: 'center',
    mb: 2,
    mt: 12,
    py: 6,
    transition: 'border-color 150ms linear, box-shadow 150ms linear',
    _hover: {
      bgColor: `background.${isDark ? 'dark' : 'light'}`,
      borderColor: isDark ? '#3f3f3f' : '#d3d3d3',
      boxShadow: 'md',
    },
  },
  signInText: {
    fontSize: 'md',
    fontWeight: 'semibold',
    letterSpacing: 'tight',
    ml: 3,
    opacity: 0.8,
  },
  signInHeading: {
    fontFamily: 'source-sans',
    fontSize: ['3xl', '3xl', '4xl'],
    fontWeight: 'semibold',
  },
  title: {
    fontFamily: 'source-sans',
    fontSize: ['md', 'lg', 'xl'],
    fontWeight: 'normal',
    mb: 1,
  },
});

export default styleGenerator;
