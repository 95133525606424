enum UserScopes {
  User = 'user',
  Chat = 'chat',
  Post = 'post',
  Bot = 'bot',

  // Nothing defined about it as such for now. Will need to discuss about it's need and
  // evaluate whether this would be really needed in the future.
  Match = 'match',
  Tag = 'tag',
}

export default UserScopes;
