import { Global } from '@emotion/react';

import FontComponent from '../types/FontComponent';

const PacificoFont: FontComponent = () => {
  const fontFace = `
    @font-face {
      font-family: 'pacifico';
      src: url('/fonts/Pacifico/Regular.ttf') format('truetype');
      font-weight: 400;
    }
  `;
  return <Global styles={fontFace} />;
};

export default PacificoFont;
