import axios from 'axios';

/**
 *
 * Collection Name: LOGIN
 * Request Name: JARVIS LOGIN
 *
 * Request URL:
 *  https://schmooze.postman.co/workspace/Team-Workspace~be8e47d7-a7e1-4687-8bba-989b22f98a54/request/2790910-939ed287-ce37-4139-8655-08350ed7ba5d
 *
 * Autmatically saves the "Authorization Token" in localstorage on success.
 * Return true if sign in is successful. Otherwise, false.
 *
 */
const auth = axios.create({
  baseURL: `https://${process.env.REACT_APP_API}.schmooze.tech/v1/auth/jarvis`,
  method: 'POST',
});

const login = (tokenId) =>
  auth('/login', {
    data: { id_token: tokenId },
  });

export { login };
