const clearLocalStorage = () => {
  localStorage.removeItem('auth_token');
  localStorage.removeItem('categories');
  localStorage.removeItem('tag_types');
  localStorage.removeItem('defaultTags');
  localStorage.removeItem('sources');
  localStorage.removeItem('users');
};

export default clearLocalStorage;
