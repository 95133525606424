import { StyleGenerator } from '../../types/Styles';

const styleGenerator: StyleGenerator = (isDark) => ({
  brandLink: {
    _hover: {
      textDecoration: 'none',
    },
  },
  brandWrapper: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  link: {
    backgroundColor: `background.${isDark ? 'dark' : 'light'}`,
    fontFamily: 'source-sans',
    fontWeight: 'semibold',
  },
  linkWrapper: {
    alignItems: 'center',
  },
  logo: {
    h: 12,
  },
  menuButtonFirst: {
    fontFamily: 'source-sans',
    mr: [2, 3],
    size: 'md',
    variant: 'outline',
  },
  menuButtonLast: {
    size: 'md',
    variant: 'outline',
  },
  menuList: {
    backgroundColor: `background.${isDark ? 'dark' : 'light'}`,
    fontFamily: 'source-sans',
    zIndex: 3,
  },
  menuWrapper: {
    fontFamily: 'source-sans',
  },
  name: {
    fontFamily: 'pacifico',
    fontSize: ['2xl', '2xl', '3xl'],
    ml: 3,
  },
  navWrapper: {
    alignItems: 'center',
    bgColor: `background.${isDark ? 'dark' : 'light'}`,
    boxShadow: 'lg',
    justifyContent: 'space-between',
    left: 0,
    position: 'absolute',
    px: [4, 5, 6, 8],
    py: [3, 3, 3, 4],
    top: 0,
    w: 'full',
    zIndex: 5,
  },
  themeSwitcher: {
    mr: [2, 3],
  },
});

export default styleGenerator;
