import { useMemo } from 'react';
import { Button, Flex, Image, Text, useColorMode } from '@chakra-ui/react';
import {
  GoogleLogin,
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';

import { useSignInContext } from '../../context/SignIn';

import { useFailureToast } from '../../hooks/useToast';
import useDocumentTitle from '../../hooks/useDocumentTitle';

import { login } from '../../apis/auth';

import ProplessFC from '../../types/ProplessFC';

import styleGenerator from './styles';

const SignInModal: ProplessFC = () => {
  useDocumentTitle('Sign In');
  const { setIsSignedIn } = useSignInContext();

  const { colorMode } = useColorMode();
  const styleProps = useMemo(
    () => styleGenerator(colorMode === 'dark'),
    [colorMode],
  );

  const failureToast = useFailureToast();

  const signIn = async (tokenId: string, signedInCallback: Function) => {
    // TODO: Refactor this using react-query
    try {
      // TODO: Define interface for this and other requests' response.
      const {
        status,
        data: { data: authData },
      } = await login(tokenId);

      if (status === 200) {
        // TODO: Change this to camelcase at all places.
        localStorage.setItem('auth_token', authData.auth_token);
        localStorage.setItem('scopes', JSON.stringify(authData.scopes));
        signedInCallback(true);
      } else {
        failureToast('Could not connect to the server');
      }
    } catch (error) {
      console.error(error);
      failureToast('Could not connect to the server');
    }
  };

  const successHandler = (
    response: GoogleLoginResponse | GoogleLoginResponseOffline,
  ) => {
    if ('tokenId' in response) {
      localStorage.setItem('email', response.profileObj.email);
      signIn(response.tokenId, setIsSignedIn);
    }
  };

  return (
    <Flex {...styleProps.modalWrapper}>
      <Flex {...styleProps.modal}>
        <Text {...styleProps.title}>Welcome to JARVIS</Text>
        <Text {...styleProps.signInHeading}>Sign in</Text>
        <GoogleLogin
          clientId={`${process.env.REACT_APP_CLIENT_ID}`}
          onSuccess={successHandler}
          render={(renderProps) => (
            <Flex
              as={Button}
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              {...styleProps.signInButton}
            >
              <Image
                src="/img/google-logo.svg"
                alt="Google Logo"
                {...styleProps.logo}
              />
              <Text {...styleProps.signInText}>Continue with Google</Text>
            </Flex>
          )}
        />
      </Flex>
    </Flex>
  );
};

export default SignInModal;
