import { Global } from '@emotion/react';

import FontComponent from '../types/FontComponent';

const SourceSansFont: FontComponent = () => {
  const fonts = [
    {
      url: '/fonts/SourceSansPro/ExtraLight.ttf',
      weight: 200,
      style: 'normal',
    },
    {
      url: '/fonts/SourceSansPro/ExtraLightItalic.ttf',
      weight: 200,
      style: 'italic',
    },
    {
      url: '/fonts/SourceSansPro/light.ttf',
      weight: 300,
      style: 'normal',
    },
    {
      url: '/fonts/SourceSansPro/LightItalic.ttf',
      weight: 300,
      style: 'italic',
    },
    {
      url: '/fonts/SourceSansPro/Regular.ttf',
      weight: 400,
      style: 'normal',
    },
    {
      url: '/fonts/SourceSansPro/RegularItalic.ttf',
      weight: 400,
      style: 'italic',
    },
    {
      url: '/fonts/SourceSansPro/SemiBold.ttf',
      weight: 600,
      style: 'normal',
    },
    {
      url: '/fonts/SourceSansPro/SemiBoldItalic.ttf',
      weight: 600,
      style: 'italic',
    },
    {
      url: '/fonts/SourceSansPro/Bold.ttf',
      weight: 700,
      style: 'normal',
    },
    {
      url: '/fonts/SourceSansPro/BoldItalic.ttf',
      weight: 700,
      style: 'italic',
    },
    {
      url: '/fonts/SourceSansPro/Black.ttf',
      weight: 900,
      style: 'normal',
    },
    {
      url: '/fonts/SourceSansPro/BlackItalic.ttf',
      weight: 900,
      style: 'italic',
    },
  ];

  let fontFaces = '';
  fonts.forEach(({ url, weight, style }) => {
    fontFaces += `
        @font-face {
          font-family: 'source-sans';
          src: url(${url}) format('truetype');
          font-weight: ${weight};
          font-style: ${style};
          font-display: swap;
        }
      `;
  });

  return <Global styles={fontFaces} />;
};

export default SourceSansFont;
