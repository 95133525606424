import { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import NavigationBar from './components/NavigationBar';
import PageLoading from './components/PageLoading';
import RedirectRoute from './components/RedirectRoute';
import SignInModal from './components/SignInModal';

import { useSignInContext } from './context/SignIn';

import pages from './pages';

import ProplessFC from './types/ProplessFC';

const queryClient = new QueryClient();

const App: ProplessFC = () => {
  const { isSignedIn, scopes } = useSignInContext();

  const renderedRoutes = pages?.map(
    ({ Component: PageComponent, path, scope }) => {
      const redirectCondition =
        !isSignedIn || (scope ? !scopes.includes(scope) : false);

      return (
        <Route
          key={path}
          path={path}
          element={<RedirectRoute redirectCondition={redirectCondition} />}
        >
          <Route
            index
            element={
              <Suspense fallback={<PageLoading />}>
                <PageComponent />
              </Suspense>
            }
          />
        </Route>
      );
    },
  );

  return (
    <Router>
      <NavigationBar />
      <QueryClientProvider client={queryClient}>
        <Routes>
          {renderedRoutes}

          {/* Login route. */}
          <Route
            path="/login"
            element={
              <RedirectRoute redirectCondition={isSignedIn} redirectPath="/" />
            }
          >
            <Route index element={<SignInModal />} />
          </Route>
        </Routes>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Router>
  );
};

export default App;
export { queryClient };
