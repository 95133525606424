import { useCallback, useRef } from 'react';
import { useToast } from '@chakra-ui/react';

import {
  UseFailureToast,
  UseInfoToast,
  UseSuccessToast,
} from '../types/hooks/UseToast';

const useSuccessToast: UseSuccessToast = (config) => {
  const toast = useToast();

  const configRef = useRef(config);

  return useCallback(
    (title: string) => {
      const id = 'success-toast';
      if (!toast.isActive(id)) {
        toast({
          id,
          title,
          position: 'bottom-right',
          status: 'success',
          variant: 'left-accent',
          isClosable: true,
          ...configRef.current,
        });
      }
    },
    [toast],
  );
};

const useFailureToast: UseFailureToast = (config) => {
  const toast = useToast();

  const configRef = useRef(config);

  return useCallback(
    (title: string) => {
      const id = 'failure-toast';
      if (!toast.isActive(id)) {
        toast({
          id,
          title,
          status: 'error',
          position: 'bottom-right',
          variant: 'left-accent',
          isClosable: true,
          ...configRef.current,
        });
      }
    },
    [toast],
  );
};

const useInfoToast: UseInfoToast = (config) => {
  const toast = useToast();

  type toastReturnType = string | number | undefined;
  const toastIdRef = useRef<toastReturnType>();

  const configRef = useRef(config);

  const showToast = useCallback(
    (title: string) => {
      const id = 'info-toast';
      if (!toast.isActive(id)) {
        toastIdRef.current = toast({
          id,
          title,
          position: 'top',
          status: 'info',
          variant: 'solid',
          duration: 60000,
          ...configRef.current,
        });
      }
    },
    [toast],
  );

  const closeToast = toast.closeAll;

  return [showToast, closeToast];
};

export { useSuccessToast, useFailureToast, useInfoToast };
