import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { ChakraProvider } from '@chakra-ui/react';

import App from './App';

import { SignInProvider } from './context/SignIn';

import theme from './config/theme';
import PacificoFont from './config/PacificoFont';
import SourceSansFont from './config/SourceSansFont';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <StrictMode>
    <ChakraProvider theme={theme}>
      {/* Fonts. */}
      <PacificoFont />
      <SourceSansFont />

      {/* Login. */}
      <SignInProvider>
        <App />
      </SignInProvider>
    </ChakraProvider>
  </StrictMode>,
);
