import { StyleProps } from '../../types/Styles';

const styleProps: StyleProps = {
  spinner: {
    size: 'xl',
    opacity: 0.75,
  },
  text: {
    color: 'text',
    fontSize: 'lg',
    mt: 4,
    opacity: 0.75,
  },
  wrapper: {
    alignItems: 'center',
    flexDir: 'column',
    justifyContent: 'center',
    maxW: '100vw',
    minH: '100vh',
    pb: 4,
    pt: [24, 24, 28],
  },
};

export default styleProps;
